import {Session, User} from '@supabase/supabase-js'
import dayjs from 'dayjs'
import {useRouter} from 'next/dist/client/router'
import {parseCookies} from 'nookies'
import {PropsWithChildren, useEffect, useRef, useState} from 'react'
import useAsyncEffect from 'use-async-effect'
import {createStore, useStore} from 'zustand'
import {persist} from 'zustand/middleware'
import {COOKIES_NEED_REDIRECTION} from '../constants/cookies'
import {SUPPORTED_LANGUAGES} from '../constants/languages'
import {zPublicUser} from '../dataModels/PublicUser'
import {useAuthStateChange} from '../hooks/useAuthStateChanged'
import {useClient} from '../hooks/useClient'
import {deleteCookieClient} from './cookieHelpers'
import {handleUserLogin} from './eventTracking/track'
import {fetchMe} from './loginHelper'

interface userState {
  session: Session | null
  user: User | null
  publicUser: zPublicUser | null
}

/**
 * Is the current user's session expired ?
 * @param session the user session
 * @returns true if session is expired, false if not
 */
const isSessionExpired = (session: Session) => {
  if (!session.expires_at) return true

  const expiration = dayjs(session.expires_at * 1000)
  return expiration.isBefore(dayjs())
}

export const AuthStore = createStore(
  persist<{state: userState; setState: (s: userState) => any}>(
    (set, get) => ({
      state: {session: null, user: null, publicUser: null},
      setState: s => set({state: s}),
    }),
    {
      name: 'auth-storage',
    }
  )
)

export const useAuthState = () => useStore(AuthStore, s => s)

/**
 *
 * @returns true if the hydration has happened (client-side), false if not (before hydratation or server-side)
 */
const useHasHydrated = () => {
  const [hasHydrated, setHasHydrated] = useState<boolean>(false)

  useEffect(() => {
    setHasHydrated(true)
  }, [])

  return hasHydrated
}

export function AuthProvider({children}: PropsWithChildren) {
  const client = useClient()
  const router = useRouter()
  const {state, setState} = useAuthState()
  const hasHydrated = useHasHydrated()
  const isInError = useRef(false)

  useAsyncEffect(async () => {
    const session = client.auth.session()

    setState({session, user: session?.user ?? null, publicUser: state.publicUser})
    const publicUser = state.publicUser || (await me(session, router.locale as SUPPORTED_LANGUAGES))
    setState({session, user: session?.user ?? null, publicUser: publicUser})
  }, [])

  useAuthStateChange(
    async (event, session) => {
      switch (event) {
        case 'SIGNED_OUT':
        case 'USER_DELETED':
          setState({session, user: null, publicUser: null})
          break
        default:
          if (
            !isInError.current &&
            (event === 'USER_UPDATED' ||
              !state.session ||
              !state.publicUser ||
              isSessionExpired(state.session))
          ) {
            const session = client.auth.session()
            debugger
            setState({session, user: session?.user ?? null, publicUser: state.publicUser})

            const publicUser =
              event === 'USER_UPDATED' || !state.publicUser
                ? await me(session, router.locale as SUPPORTED_LANGUAGES)
                : state.publicUser
            if (!publicUser) {
              isInError.current = true
            } else {
              if (session?.user) {
                handleUserLogin(session?.user)
              }
              const {needRedirection} = parseCookies()
              if (needRedirection) {
                await router.push(needRedirection, undefined, {locale: router.locale})
                deleteCookieClient(COOKIES_NEED_REDIRECTION)
              }
              setState({session, user: session?.user ?? null, publicUser: publicUser})
            }
          } else {
            setState({session, user: session?.user ?? null, publicUser: state.publicUser})
          }
      }
    },
    [state.session, state.user]
  )
  if (!hasHydrated) return <></>

  return <>{children}</>
}

const me = async (
  session: Session | null,
  language: SUPPORTED_LANGUAGES
): Promise<zPublicUser | null> => {
  if (session && session.user) {
    return await fetchMe(language)
  }
  return null
}
